import React, { useReducer, createContext } from "react";

type SearchContextState = {
  searchValue: string;
  instituteType: null | number;
  postCode: null | string;
  postCodeId: null | number;
  label: null | string;
  defaultPostCode: null | string;
  defaultPostCodeId: null | number;
  defaultLabel: null | string;
};

const initialState = {
  searchValue: "",
  instituteType: null,
  postCode: null,
  postCodeId: null,
  label: null,
  defaultPostCode: null,
  defaultPostCodeId: null,
  defaultLabel: null,
};

const SearchContext = createContext<{
  state: SearchContextState;
  dispatch: Function;
}>({
  state: initialState,
  dispatch: () => null,
});

function searchReducer(
  state: SearchContextState,
  action: {
    type: string;
    [key: string]: any;
  }
) {
  if (process.env.NODE_ENV === "development") {
    console.group("SEARCH CONTEXT ACTION: ", action.type);
    console.table(action);
    console.groupEnd();
  }

  switch (action.type) {
    case "SET_SEARCHVALUE":
      return {
        ...state,
        searchValue: action.searchValue,
      };

    case "SELECT_POSTCODE":
      return {
        ...state,
        postCode: action.postCode,
        postCodeId: action.postCodeId,
        label: action.label,
      };

    case "SET_INSTITUTETYPE":
      return {
        ...state,
        instituteType: action.instituteType,
      };

    case "SET_POSTCODE":
      return {
        ...state,
        postCode: action.postCode,
        postCodeId: action.postCodeId,
        label: action.label,
      };

    case "SET_DEFAULT_POSTCODE":
      return {
        ...state,
        defaultPostCode: action.postCode,
        defaultPostCodeId: action.postCodeId,
        defaultLabel: action.label,
      };

    default:
      return state;
  }
}

const SearchProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const value = { state, dispatch };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchProvider, SearchContext };
