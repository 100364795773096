import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchContext } from "../context/searchContext";
import { SettingsContext } from "../context/settingsContext";
import i18n from "../misc/i18n";
import InstitutesSelector from "./InstitutesSelector";
import SearchInput from "./SearchInput";
import HERO_IMAGE_FALLBACK from "../assets/images/Kontakt_Karte_V2.jpg";
import useInstitutes from "../hooks/queries/useInstitutes";
import Spinner from "./Spinner";

type SearchProps = {
  withImage?: boolean;
};

const Search: React.FC<SearchProps> = ({ withImage = false }) => {
  const history = useHistory();
  const { state, dispatch } = useContext(SearchContext);
  const { initialInstituteType, heroImagePath } = useContext(SettingsContext);
  const { isLoading } = useInstitutes(state.instituteType, state.postCodeId);

  const [missingPlz, setMissingPlz] = useState(false);
  const [missingPostCodeSelection, setMissingPostCodeSelection] = useState(false);
  const [missingInstitute, setMissingInstitut] = useState(false);

  const [institute, setInstitute] = useState(state.instituteType);

  // syncs local component state to context state
  // (needs to be separate to have form submit behaviour)
  useEffect(() => {
    if (state.instituteType) {
      setInstitute(state.instituteType);
    }
  }, [state.instituteType]);

  // checks if data-initial-institute is set, sets initial value if
  // no params institute is present
  useEffect(() => {
    if (state.instituteType == null && initialInstituteType !== null) {
      dispatch({
        type: "SET_INSTITUTETYPE",
        instituteType: initialInstituteType,
      });
    }
  }, [initialInstituteType, dispatch, state.instituteType]);

  function pushResultsRoute() {
    const newRoute = `/results/${institute}/${state.defaultPostCodeId}`;
    history.push(newRoute);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setMissingPlz(false);
    setMissingPostCodeSelection(false);
    setMissingInstitut(false);

    const {searchValue, defaultPostCodeId, defaultPostCode, defaultLabel } = state;

    if (searchValue.length < 2 || defaultPostCodeId === null || institute === null || isNaN(institute)) {
      if (searchValue.length < 2) {
        setMissingPlz(true);
      }
      if (institute == null || isNaN(institute)) {
        setMissingInstitut(true);
      }

      if (defaultPostCodeId === null) {
        setMissingPostCodeSelection(true);
      }

      return null;
    }

    dispatch({
      type: "SET_SEARCHVALUE",
      searchValue: defaultPostCode,
    });
    dispatch({
      type: "SELECT_POSTCODE",
      postCode: defaultPostCode,
      postCodeId: defaultPostCodeId,
      label: defaultLabel,
    });
    dispatch({
      type: "SET_INSTITUTETYPE",
      instituteType: institute,
    });

    pushResultsRoute();
  }

  return (
    <section
      className="section section--light-blue"
      id="search-box"
    >
      <div className="container">
        {withImage ? (
          <div className="row no-gutters">
            <picture className="contactsearch__image">
              <source
                media="(max-width: 767.9px)"
                srcSet={`${heroImagePath || HERO_IMAGE_FALLBACK}`}
              />
              <source
                media="(max-width: 1023.9px)"
                srcSet={`${heroImagePath || HERO_IMAGE_FALLBACK}`}
              />
              <img
                className="img-fluid content-image"
                srcSet={`${heroImagePath || HERO_IMAGE_FALLBACK}`}
                alt="Kontaktsuche"
              />
            </picture>
          </div>
        ) : null}
        <div className="container">
          <div className="row justify-content-center">
            <div
              className={`col-12 ${
                withImage ? "col-xl-6 offset-xl-6" : "col-xl-9"
              } px-0`}
            >
              <div className={`${withImage ? "contactsearch" : ""}`}>
                <h2
                  className={`mb-3 text-center ${
                    withImage ? "text-xl-left" : ""
                  }`}
                >
                  {i18n.search.headline}
                </h2>
                <form className="custom-form" onSubmit={handleSubmit}>
                  <div className="form-row justify-content-end items-end">
                    <div className="form-group col-md-6 d-flex flex-column align-bottom justify-content-end">
                      <label htmlFor="contactsearchType">
                        {i18n.search.typeSelectTitle}:
                      </label>
                      <InstitutesSelector
                        {...{
                          setInstitute,
                          institute,
                        }}
                      />
                      {missingInstitute ? (
                        <small>Bitte ein Institut angeben.</small>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="contactsearchZip">
                        {i18n.search.plzInputTitle}:
                      </label>
                      <SearchInput handleSuggestionPicked={pushResultsRoute}/>
                      {missingPlz ? (
                        <small>Bitte eine gültige PLZ angeben.</small>
                      ) : null}
                      {missingPostCodeSelection ? (
                          <small>Bitte eine Postleitzahl auswählen.</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      <button
                        className="btn btn-primary btn-block text-center d-flex align-items-center"
                        disabled={isLoading || state.defaultPostCodeId == null}
                      >
                        {isLoading ? <Spinner /> : i18n.search.buttonLabel}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Search;
